var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.paginationItems.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.paginationItems.total,"loading":_vm.loading,"sort-by":"id"},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.trans('buttons.promoCodeItem.newItem')))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialogLoaded)?_c('promo-code-item-form',{attrs:{"options":_vm.options,"itemId":_vm.editedItem,"promo_code_id":_vm.$route.params.id},on:{"saved":_vm.onSave,"close":_vm.close}}):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'admin.promoCodes.activations', params: {id: item.id}}}},[_vm._v(_vm._s(item.label))])]}},{key:"item.valid_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.valid_from))+" ")]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.valid_to))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }